<template>
  <div class="d-flex flex-column">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row class="mt-1">
        <v-col cols="12" sm="12" offset-sm="0" md="10" offset-md="1">
          <b-card
            header="Primary"
            header-bg-variant="primary"
            header-text-variant="white"
            v-if="posData"
          >
            <template #header>
              <h6 class="mb-0">Change GPS</h6>
            </template>
            <b-card-text class="py-3 px-3">
              <v-row>
                <v-col cols="12" sm="12" offset-sm="0" md="10" offset-md="1">
                  <h6 class="my-2">
                    {{ $t("Picture of the Real entrance of the POS") }}
                    <span class="required ml-2">*</span> :
                  </h6>
                  <div
                    class="d-flex align-center justify-content-center webcameraWrapper"
                  >
                    <gennera-picture-input
                      :crop="false"
                      class="my-gennera-picture-input"
                      :ref="`genneraPictureInputInstance`"
                      size="5"
                      width="500"
                      accept="image/jpeg,image/png"
                      button-class="btn btn-outline-info"
                      aspect="Landscape"
                      :customStrings="{
                        upload: this.$t('uploading_image'),
                        change: this.$t('change'),
                        drag: 'Selecciona una foto',
                        tap: 'toma una foto'
                      }"
                      @change="onPicturePhoto"
                      :capture="`camera`"
                    >
                    </gennera-picture-input>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="saveData">
                <v-col cols="12" sm="12" offset-sm="0" md="10" offset-md="1">
                  <h6 class="my-2">
                    Dirección actual:
                    <span class="required ml-2">*</span> :
                  </h6>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="saveData.address"
                        append-icon="mdi-map-marker"
                        outlined
                        class="editpos_input"
                        :readonly="true"
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="saveData.town"
                        v-bind:label="$t('town')"
                        outlined
                        class="editpos_input"
                        :readonly="true"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="saveData.postalCode"
                        label="CP"
                        outlined
                        class="editpos_input"
                        :readonly="true"
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="saveData.addressObservation"
                        v-bind:label="$t('address observation')"
                        outlined
                        class="editpos_input"
                        :readonly="true"
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="mb-1" v-if="googleNewAddressData">
                <v-col cols="12" sm="12" offset-sm="0" md="10" offset-md="1">
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <h6 class="my-2">Introduce la nueva dirección:</h6>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="googleNewAddressData.address"
                            v-bind:label="$t('address')"
                            v-if="this.isManualAddress"
                            outlined
                            class="editpos_input"
                            :readonly="this.isManualAddress"
                          ></v-text-field>
                          <input
                            v-model="googleNewAddressData.address"
                            type="text"
                            :placeholder="$t('address')"
                            required
                            :rules="requiredRules"
                            ref="googleaddressbar"
                            class="googleAddressFinder"
                            :readonly="this.isManualAddress"
                            v-show="!this.isManualAddress"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            v-model="googleNewAddressData.town"
                            v-bind:label="$t('town')"
                            required
                            :rules="!this.isManualAddress ? requiredRules : []"
                            outlined
                            class="editpos_input"
                            :readonly="true"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            v-model="googleNewAddressData.postalCode"
                            label="CP"
                            required
                            :rules="!this.isManualAddress ? requiredRules : []"
                            outlined
                            class="editpos_input"
                            :readonly="true"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="googleNewAddressData.addressObservation"
                            v-bind:label="$t('address observation')"
                            outlined
                            class="editpos_input"
                            :readonly="this.isManualAddress"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="mb-1" v-show="isManualAddress" v-if="manualData">
                <v-col cols="12" sm="12" offset-sm="0" md="10" offset-md="1">
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h6>
                        Introduce manualmente la nueva dirección:
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="manualData.address"
                            v-bind:label="$t('address')"
                            required
                            :rules="requiredRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            v-model="manualData.town"
                            v-bind:label="$t('town')"
                            required
                            :rules="requiredRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            v-model="manualData.postalCode"
                            label="CP"
                            required
                            :rules="requiredRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="manualData.addressObservation"
                            v-bind:label="$t('address observation')"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" offset-sm="0" md="10" offset-md="1">
                  <div class="text-right">
                    <a style="color: #1976d2;" @click="toggleCantfindAddress">
                      La dirección no sale
                    </a>
                  </div>
                </v-col>
              </v-row>
            </b-card-text>
            <template #footer>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  class="d-flex justify-content-center"
                >
                  <v-btn
                    color="success"
                    dark
                    class="mx-1 my-2"
                    :loading="isSaving"
                    @click="onSaveClick"
                  >
                    {{ $t("save") }}
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </b-card>

          <b-alert v-else show variant="info">
            <b-icon
              icon="arrow-counterclockwise"
              animation="spin-reverse"
            ></b-icon>
            cargando datos...
          </b-alert>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
import { logInfo, logError } from "@/utils";
import webCamera from "@/components/webCamera.vue";
import haversine from "haversine-distance";
import GenneraPictureInput from "@/components/GenneraPictureInput.vue";

export default {
  name: "geolocation",
  props: ["id", "worksessionPosId"],
  components: {
    webCamera,
    GenneraPictureInput
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("myroute", [
      "currentPosData",
      "currentWorksessionData",
      "currentPosition",
      "deviceInformation"
    ])
  },

  data: function() {
    return {
      isSaving: false,
      posData: null,
      valid: true,
      requiredRules: [v => !!v || "Required"],
      currentCameraPosition: null,
      saveData: null,
      googleNewAddressData: null,
      positionGoogleAddress: {
        latitude: null,
        longitude: null
      },
      manualData: null,
      detectedData: null,

      savedAddress: null,

      isManualAddress: false,
      addressAutoComplete: null,
      photoTaken: null
    };
  },
  methods: {
    async getAddress(latlng) {
      console.log(latlng);
      const geocoder = new window.google.maps.Geocoder();
      try {
        const response = await geocoder.geocode({ location: latlng });
        console.log(response);
        if (response.results[0]) {
          return response.results[0];
        } else {
          window.alert("No results found");
          return null;
        }
      } catch (error) {
        window.alert("Geocoder failed due to: " + error);
        return null;
      }
    },
    placeChangedListener() {
      var address = this.addressAutoComplete.getPlace();

      console.log(address);
      if (address && address.address_components) {
        let addressDetail = {
          address: address.formatted_address,
          town: null,
          postalCode: null,
          latitude: null,
          longitude: null
        };
        this.savedAddress = address.formatted_address;
        if (address?.geometry?.location) {
          const latitude = address.geometry.location.lat();
          const longitude = address.geometry.location.lng();
          this.positionGoogleAddress = {
            latitude,
            longitude
          };
          addressDetail = {
            ...addressDetail,
            latitude,
            longitude
          };
        }
        // types - postal_code
        let postal_code_item = address.address_components.filter(el =>
          el.types.includes("postal_code")
        );
        if (postal_code_item && postal_code_item.length > 0) {
          addressDetail.postalCode = postal_code_item[0].long_name;
        }
        // town types - locality
        let town_item = address.address_components.filter(el =>
          el.types.includes("locality")
        );
        if (town_item && town_item.length > 0) {
          addressDetail.town = town_item[0].long_name;
        }
        this.googleNewAddressData = {
          ...this.googleNewAddressData,
          ...addressDetail
        };
        console.log("this.googleNewAddressData - ", this.googleNewAddressData);
        this.changeLocation();
      }

      // if (!address.geometry) {
      //   // User entered the name of a Place that was not suggested and
      //   // pressed the Enter key, or the Place Details request failed.
      //   // Do anything you like with what was entered in the ac field.
      //   console.log("You entered: " + address.name);
      //   return;
      // }
    },
    async toggleCantfindAddress() {
      this.isManualAddress = !this.isManualAddress;
      if (this.isManualAddress) {
        // this.addressAutoComplete = new window.google.maps.places.Autocomplete(
        //   this.$refs.googleaddressbar
        // );
        // this.addressAutoComplete.addListener(
        //   "place_changed",
        //   this.placeChangedListener
        // );
        // this.initSavePosData(this.posData);
      }
    },
    initSavePosData(posData) {
      if (posData) {
        const {
          id,
          address,
          town,
          postalCode,
          addressObservation,
          latitude,
          longitude
        } = posData;
        this.saveData = {
          id,
          address,
          town,
          postalCode,
          addressObservation,
          latitude,
          longitude
        };
      }
    },
    initManualPosData(posData) {
      if (posData) {
        const {
          id,
          address,
          town,
          postalCode,
          addressObservation,
          latitude,
          longitude,
          isGoogleAddress
        } = posData;
        this.googleNewAddressData = {
          id,
          address: null,
          town: null,
          postalCode: null,
          addressObservation,
          latitude: null,
          longitude: null,
          isGoogleAddress
        };
        this.manualData = {
          id,
          address,
          town,
          postalCode,
          addressObservation,
          latitude,
          longitude,
          isGoogleAddress
        };
      }
    },
    changeLocation() {
      if (
        this.currentCameraPosition &&
        this.currentCameraPosition?.latitude &&
        this.currentCameraPosition?.longitude &&
        this.positionGoogleAddress &&
        this.positionGoogleAddress?.latitude &&
        this.positionGoogleAddress?.longitude
      ) {
        console.log(
          "cameraPosition, googlePosition - ",
          this.currentCameraPosition,
          this.positionGoogleAddress
        );
        let posLocationPt = {
          lat: this.currentCameraPosition.latitude,
          lng: this.currentCameraPosition.longitude
        };
        let currentLocationPt = {
          lat: this.positionGoogleAddress.latitude,
          lng: this.positionGoogleAddress.longitude
        };
        let haversine_m = haversine(posLocationPt, currentLocationPt); //Results in meters (default)
        console.log("haversine_m - ", haversine_m);
        if (haversine_m <= 200) {
          if (!this.isManualAddress) {
            this.googleNewAddressData = {
              ...this.googleNewAddressData,
              latitude: this.positionGoogleAddress.latitude,
              longitude: this.positionGoogleAddress.longitude
            };
          }
        } else {
          logError(
            "la distancia entre la dirección introducida y la las coordenadas de la foto es muy grande.  Vuelve a tomar la foto o revisa la dirección."
          );
        }
      }
    },
    changedCurrentPositionByCamera(currentCameraPosition) {
      console.log("currentCameraPosition - ", currentCameraPosition);
      if (currentCameraPosition) {
        const { latitude, longitude } = currentCameraPosition;
        if (!this.isManualAddress) {
          this.googleNewAddressData = {
            ...this.googleNewAddressData,
            latitude,
            longitude
          };
        } else {
          this.manualData = {
            ...this.manualData,
            latitude,
            longitude
          };
        }
        this.changeLocation();
      }
    },
    onPicturePhoto() {
      this.photoTaken = this.$refs?.genneraPictureInputInstance.file;
      this.$store.dispatch(
        "myroute/getCurrentPosition",
        (error, currentPosition, formatedAddress) => {
          if (!error) {
            this.currentCameraPosition = currentPosition;
            this.changedCurrentPositionByCamera(currentPosition);
          }
        }
      );
    },
    async detectedLocation() {
      this.photoTaken = await this.$refs.webCameraInstance.getTakenPhotoFile();
      this.currentCameraPosition = this.$refs.webCameraInstance.getCurrentPosition();
      if (this.currentCameraPosition) {
        const { latitude, longitude } = this.currentCameraPosition;
        this.changedCurrentPositionByCamera({ latitude, longitude });
        let latlng = {
          lat: latitude,
          lng: longitude
        };
        const gAddress = await this.getAddress(latlng);
        console.log("addressaddress - ", gAddress);
        if (gAddress) {
          let addressDetail = {
            address: gAddress?.formatted_address,
            town: null,
            postalCode: null,
            latitude,
            longitude
          };
          // types - postal_code
          let postal_code_item = gAddress.address_components.filter(el =>
            el.types.includes("postal_code")
          );
          if (postal_code_item && postal_code_item.length > 0) {
            addressDetail.postalCode = postal_code_item[0].long_name;
          }
          // town types - locality
          let town_item = gAddress.address_components.filter(el =>
            el.types.includes("locality")
          );
          if (town_item && town_item.length > 0) {
            addressDetail.town = town_item[0].long_name;
          }

          this.detectedData = addressDetail;
        }
      } else {
        logError("We can't access the device for detecting location !");
      }
    },
    async onSaveClick() {
      console.log("body - ", this.googleNewAddressData, this.savedAddress);
      if (this.$refs.form.validate()) {
        let id = this.posData.id;
        let body = {};
        if (!this.isManualAddress) {
          body = {
            id,
            isGoogleAddress: true,
            ...this.googleNewAddressData
          };
          if (!body.address) {
            logError("¡Por favor ingrese la dirección!");
            return;
          }
          if (body.address !== this.savedAddress) {
            logError(
              "Seleccione la dirección utilizando la entrada de Google Map"
            );
            return;
          }
        } else {
          body = {
            id,
            isGoogleAddress: false,
            ...this.manualData
          };
        }
        let photoTaken = this.photoTaken;
        if (photoTaken) {
          let { data } = await ApiService.uploadImage(photoTaken);
          body.photoId = data.id;
        } else {
          logError("¡Deberías tomar una foto!");
          return;
        }
        const { routeId, posId } = this.currentWorksessionData;
        console.log("USER - ", this.user.id);
        console.log("this.currentWorksessionData - ", routeId, posId);
        body = {
          ...body,
          routeId,
          userId: this.user.id
        };
        console.log("body - ", body);
        this.isSaving = true;
        try {
          if (body.id) {
            await ApiService.put(`worksession/savechangepos/${body.id}`, body);
          }
          this.isSaving = false;
          logInfo("Éxito");
          // this.init();
        } catch (error) {
          this.isSaving = false;
        }
      }
    },
    async init() {
      await this.$store.dispatch("myroute/getWorksessionPos", {
        worksessionPosId: this.worksessionPosId
      });
      await this.$store.dispatch("myroute/getCurrentPosData", {
        sessionId: this.id,
        worksessionPosId: this.worksessionPosId
      });
      this.posData = this.currentPosData;
      if (this.posData) {
        this.initSavePosData(this.posData);
        console.log(
          "currentWorksessionData - ",
          this.currentWorksessionData,
          this.posData
        );

        let foundManualData = this.getPosChangeGeolocationManualData(
          this.currentWorksessionData,
          this.user.id
        );
        console.log("foundManualData - ", foundManualData);
        if (!foundManualData) {
          this.initManualPosData(this.posData);
        } else {
          const {
            posId,
            newAddress,
            newTown,
            newPostalCode,
            newLat,
            newLng,
            isGoogleAddress,
            newAddressObservation
          } = foundManualData;
          this.initManualPosData({
            id: posId,
            address: newAddress,
            town: newTown,
            postalCode: newPostalCode,
            addressObservation: newAddressObservation,
            latitude: newLat,
            longitude: newLng,
            isGoogleAddress
          });
        }
        this.$nextTick(function() {
          this.addressAutoComplete = new window.google.maps.places.Autocomplete(
            this.$refs.googleaddressbar
          );
          this.addressAutoComplete.addListener(
            "place_changed",
            this.placeChangedListener
          );
        });
      }
    }
  },

  async mounted() {
    await this.init();
  }
};
</script>

<style>
span.required {
  color: #f00;
  font-weight: bold;
}
input.googleAddressFinder {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition-property: height, min-height;
  margin-top: 16px;
  font-size: 16px;
  height: 33px;
}
</style>
